/* .App {
  margin: 0px 10px 0px 10px;
  min-height: 100%;
  padding-bottom: 50px;
  position: relative;
  background-color: aliceblue;
} */
.App {
  margin: 0px 10px 0px 10px;
  background-color: aliceblue;
  min-height: 100%;
  position: relative;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* To transition color scheme */
img {
  border-radius: 50%;
    filter: gray; /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all .8s ease-in-out; 
}
img:hover {
  filter: none;
    -webkit-filter: grayscale(0);
    -webkit-transform: scale(1.01);
}

.App-header {
  position: sticky;
  top: 0%;
  z-index: 1;
  color: white;
  background-color: #282c34;
  margin-bottom: 20px;
  font-size: calc(8px + 1vmin);
}

.last-item {
  /* display: flex;
  flex-direction: row; */
  align-self: flex-end;
}

.download-button {
  color: gray;
  font-style: italic;
}
.downbuttonload-:hover {
  text-decoration: none;
  font-style: italic;
}

.nav-items {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  */
  
}

.nav-link {
  margin-right: 50px;
}

.main-body {
  padding: 10px;
  padding-bottom: 200px;
}

.App-footer {
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 200px;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1.5vmin);
  color: white;
}

.footer {
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 200px;
}
.copyright {
  align-self: flex-end;
}

#nav-image {
  display: flex;
  justify-content: left;
  margin-right: 50px;
}

.name-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-section {
  margin-bottom: 20px;
}

.skill-section > p {
  font-size: 20px;
  color: grey;
  font-weight: bolder;
}

.about > p {
  font-size: 20px;
  color: grey;
  /* font-weight: bolder; */
}

h4 {
  text-align: center;
  font-weight: bolder;
  color: rgb(36, 39, 46);
}

h5 {
  color: grey;
}

.copyright {
margin-bottom: 0px;
color: grey;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* About Page */
.about {
  /* background-image: url('images/tenniscourt.jpeg');
  background-repeat: no-repeat; */
  display: flex;
  flex-direction: column;
  align-items: center;
}